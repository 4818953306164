<template>
  <div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.25%;">
    <iframe :src="`https://rutube.ru/play/embed/${source}?isfulltab=true`"
            style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;"
            allowfullscreen
            scrolling="no"
            allow="encrypted-media;"/>
  </div>
</template>

<script>
export default {
  name: 'VideoRuTube',
  props: {
    source: {
      type: String,
      required: true,
    },
  },
};
</script>
